/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-activity': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g><g id="tools"><g><path d="M27.8625488.9259338c-1.8499146,0-3.3495483,1.4996338-3.3495483,3.3495483s1.4996338,3.3496094,3.3495483,3.3496094,3.3495483-1.4996948,3.3495483-3.3496094-1.4996338-3.3495483-3.3495483-3.3495483Z"/><path d="M27.8599854,10.8999939c-3.6499634,0-6.6199951-2.9699707-6.6199951-6.6199951,0-.1900024.0100098-.3699951.0300293-.5499878H5.8200073c-2.1099854,0-3.8200073,1.7099609-3.8200073,3.8200073v18.8499756c0,2.1099854,1.710022,3.8200073,3.8200073,3.8200073h18.8499756c2.1100464,0,3.8200073-1.710022,3.8200073-3.8200073v-15.5299683c-.2099609.0099487-.4199829.0299683-.6300049.0299683ZM23.2999878,18.1800232h-2.4099731l-2.1900024,3.7099609c-.210022.3699951-.6099854.5900269-1.0400391.5900269h-.0199585c-.4400024-.0100098-.8400269-.25-1.0400391-.6400146l-3.5299683-6.5700073-1.1300049,2.1699829c-.210022.4000244-.6199951.6500244-1.0700073.6500244h-3.6799927c-.6699829,0-1.210022-.539978-1.210022-1.2000122,0-.6699829.5400391-1.2099609,1.210022-1.2099609h2.9500122l1.8399658-3.5599976c.210022-.4000244.6199951-.6500244,1.0700073-.6500244.4400024.0299683.8599854.2399902,1.0700073.6400146l3.5900269,6.7000122,1.4400024-2.4500122c.2199707-.3699951.6199951-.5900269,1.0499878-.5900269h3.0999756c.6699829,0,1.210022.5400391,1.210022,1.210022,0,.6599731-.5400391,1.2000122-1.210022,1.2000122Z"/></g></g></g>'
  }
})



