/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g><g id="tools"><path d="M31.4268335,28.8589482l-7.3277857-7.3277857c1.7212714-2.2334243,2.7568625-5.0212166,2.7568625-8.0524069C26.8559102,6.1837805,20.9207319.2486022,13.6257568.2486022S.3956035,6.1837805.3956035,13.4787556s5.9351783,13.2301534,13.2301534,13.2301534c3.0311903,0,5.8189826-1.0355911,8.0524069-2.7568625l7.3277857,7.3277857c.3343762.3343762.7724091.5015644,1.210442.5015644s.8760658-.1671881,1.210442-.5015644c.6687525-.6687525.6687525-1.7521315,0-2.420884ZM3.8196162,13.4787556c0-5.4068639,4.3992768-9.8061406,9.8061406-9.8061406s9.8061406,4.3992768,9.8061406,9.8061406-4.3992768,9.8061406-9.8061406,9.8061406S3.8196162,18.8856194,3.8196162,13.4787556Z"/></g></g>'
  }
})



