/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'importers': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g><g id="tools"><g><path d="M26.6095104,31.8904877H5.7149792c-2.9003906,0-5.2602539-2.359375-5.2602539-5.2597656v-11.5131836c0-2.9003906,2.3598633-5.2602539,5.2602539-5.2602539h4.9887695c.5522461,0,1,.4477539,1,1s-.4477539,1-1,1h-4.9887695c-1.7978516,0-3.2602539,1.4624023-3.2602539,3.2602539v11.5131836c0,1.7978516,1.4624023,3.2597656,3.2602539,3.2597656h20.8945312c1.7978516,0,3.2597656-1.4619141,3.2597656-3.2597656v-11.5131836c0-1.7978516-1.4619141-3.2602539-3.2597656-3.2602539h-5.609375c-.5527344,0-1-.4477539-1-1s.4472656-1,1-1h5.609375c2.9003906,0,5.2597656,2.3598633,5.2597656,5.2602539v11.5131836c0,2.9003906-2.359375,5.2597656-5.2597656,5.2597656Z"/><path d="M16.1622448,25.9334564c-.2651367,0-.5195312-.1054688-.7070312-.2929688l-8.1186523-8.1181641c-.390625-.3911133-.390625-1.0239258,0-1.4145508s1.0234375-.390625,1.4140625,0l7.4116211,7.4116211,7.4111328-7.4116211c.390625-.390625,1.0234375-.390625,1.4140625,0s.390625,1.0234375,0,1.4145508l-8.1181641,8.1181641c-.1875.1875-.4418945.2929688-.7070312.2929688Z"/><path d="M16.1622448,25.9334564c-.5522461,0-1-.4472656-1-1V1.1395111c0-.5522461.4477539-1,1-1s1,.4477539,1,1v23.7939453c0,.5527344-.4477539,1-1,1Z"/></g></g></g>'
  }
})



