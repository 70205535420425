/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g><g id="tools"><path d="M31.0134577,2.180623H1.3037338C.5757824,2.180623-.0160959,2.7725013-.0160959,3.5004526v25.0222837c0,.7279513.5918783,1.3266395,1.3198296,1.3266395h29.7097239c.7279513,0,1.3266395-.5986882,1.3266395-1.3266395V3.5004526c0-.7279513-.5986882-1.3198296-1.3266395-1.3198296ZM10.5697391,4.4461125c.6667039,0,1.2041862.5442506,1.2041862,1.2109546,0,.6667455-.5374823,1.2109961-1.2041862,1.2109961-.6735138,0-1.2109546-.5442506-1.2109546-1.2109961,0-.6667039.5374408-1.2109546,1.2109546-1.2109546ZM7.0116595,4.4461125c.6735138,0,1.2109546.5442506,1.2109546,1.2109546,0,.6667455-.5374408,1.2109961-1.2109546,1.2109961-.6667455,0-1.2041862-.5442506-1.2041862-1.2109961,0-.6667039.5374408-1.2109546,1.2041862-1.2109546ZM3.4603482,4.4461125c.6667455,0,1.2109961.5442506,1.2109961,1.2109546,0,.6667455-.5442506,1.2109961-1.2109961,1.2109961-.6667039,0-1.2109546-.5442506-1.2109546-1.2109961,0-.6667039.5442506-1.2109546,1.2109546-1.2109546ZM29.9181465,27.5022508H2.4058548V9.1335527h27.5122917v18.3686981Z"/><rect x="5.8822989" y="11.3990007" width="8.0074231" height="9.2251876" rx=".6803237" ry=".6803237"/><rect x="16.1620214" y="18.3179225" width="10.279681" height="2.3062658"/><rect x="5.8822989" y="22.9304955" width="20.5594035" height="2.3063073"/><rect x="16.1620214" y="13.7053079" width="10.279681" height="2.3063073"/></g></g>'
  }
})



